<template>
  <modal name="update-reseller" class="modal-inner modal-update-reseller" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
    <span class="close-button" @click="hide('update-reseller')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      <span v-if="updateInfo">{{ updateInfo.name }}</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formUpdateReseller" v-if="updateInfo != null">
        <div v-if="user.role == 'admin'" class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="updateInfo.reseller_type" :options="resellerTypes" nameSelector="name" optionSelector="id" :field_name="$t('reseller_edit_modal.type')" />
          </div>
          <div v-if="updateInfo.reseller_type == 'alarm_center'" class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="updateInfo.alarm_center" :options="alarmCenters" nameSelector="name" optionSelector="_id" :field_name="$t('reseller_edit_modal.alarm_center')" rules="required" />
          </div>
        </div>

        <div v-if="user.role == 'admin'" class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.name" type="text" :field_name="$t('reseller_edit_modal.name')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.company_number" type="text" :field_name="$t('reseller_edit_modal.org_number')" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.email" type="text" :field_name="$t('reseller_edit_modal.email')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.phone" type="text" :field_name="$t('reseller_edit_modal.phone')" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.address" type="text" :field_name="$t('reseller_edit_modal.address')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.zip_code" type="text" :field_name="$t('reseller_edit_modal.zip_code')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.city" type="text" :field_name="$t('reseller_edit_modal.city_town')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2" v-if="user.role == 'admin'">
            <BaseInput v-model="updateInfo.reference" type="text" :field_name="$t('reseller_edit_modal.ref')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.invoice" type="text" :field_name="$t('reseller_edit_modal.e_invoice')" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.contact_person" type="text" :field_name="$t('reseller_edit_modal.contact_person')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.contact_email" type="text" :field_name="$t('reseller_edit_modal.contact_email')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.contact_phone" type="text" :field_name="$t('reseller_edit_modal.contact_phone')" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseTextarea rows="5" v-model="updateInfo.description" :field_name="$t('reseller_edit_modal.description')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <label class="text-gray-600 text-sm font-bold pb-2 font-serif">Logo</label>
            <img :src="previewImage" class="px-2 py-2 max-h-28" />
            <input type="file" accept="image/*" ref="imageUpdate" @change="fileChangeUpdate()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
          </div>
        </div>

        <div v-if="user.role == 'admin'" class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap" v-if="user.role == 'admin'">
          <div class="w-full md:w-1/3 px-2 py-2" v-for="o in updateInfo.pricelist" :key="o.id">
            <div class="w-full text-md font-bold mb-4">
              <label class="inline-flex items-center mt-3">
                <input v-model="o.active" type="checkbox" class="form-checkbox h-4 w-4" />
                <span class="ml-2 text-gray-600 text-md font-serif font-bold" v-if="o.id == 'cash'">{{ $t("reseller_edit_modal.cash") }}</span>
                <span class="ml-2 text-gray-600 text-md font-serif font-bold" v-else-if="o.id == 'rental'">{{ $t("reseller_edit_modal.for_rent") }}</span>
                <span class="ml-2 text-gray-600 text-md font-serif font-bold" v-else-if="o.id == 'kickback'">{{ $t("reseller_edit_modal.kickback") }}</span>
              </label>
            </div>
            <div v-if="o.active">
              <div class="w-full text-md font-sans font-semibold mt-5 mb-6">
                <span v-if="o.id == 'cash'">{{ $t("reseller_edit_modal.cash") }}</span>
                <span v-else-if="o.id == 'rental'">{{ $t("reseller_edit_modal.for_rent") }}</span>
                <span v-else-if="o.id == 'kickback'">{{ $t("reseller_edit_modal.kickback") }}</span>
                {{ $t("reseller_edit_modal.price_list") }}
              </div>
              <div class="flex flex-wrap my-2" v-for="p in o.prices" :key="p.id">
                <div class="w-2/6 text-sm" v-bind:class="{ 'opacity-40': p.price == 0 }">
                  {{ p.name }}
                </div>
                <div class="w-2/6" v-bind:class="{ 'opacity-40': p.price == 0 }">
                  <ValidationProvider :name="p.name" rules="required" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <input type="number" v-model="p.price" min="0" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                in
                <div class="w-1/6" v-bd:class="{ 'opacity-40': p.price == 0 }">
                  <span class="pl-2 text-xs" v-if="p.price_type == 'month'"> / {{ $t("reseller_edit_modal.month") }}</span>
                </div>
                <div class="w-1/6">
                  <span v-if="p.price != 0" @click.prevent="setPriceZero(o.id, p.id)" class="cursor-pointer text-xs"><BaseIcon icon="times-circle" class="text-red-400"/></span>
                  <span v-else @click.prevent="resetSchemePrices(o.id, p.id)" class="cursor-pointer text-xs"><BaseIcon icon="redo" class="text-red-400"/></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="user.role == 'admin'" class="mx-2 border-b border-secondary-400 my-5"></div>
        <div v-if="user.role == 'admin'" class="flex flex-wrap">
          <div class="w-full font-bold text-md px-2 py-2">{{ $t("reseller_edit_modal.special_access") }}</div>
          <template v-for="s in specialAccess">
            <div class="w-full md:w-1/3 px-2 py-2" v-bind:key="s.id">
              <label class="inline-flex items-center mt-3">
                <input v-model="updateInfo.special_access" :value="s.id" type="checkbox" class="form-checkbox h-4 w-4" />
                <span class="ml-2 text-gray-600 text-sm font-serif font-bold">{{ s.name }}</span>
              </label>
            </div>
          </template>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('update-reseller')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updateReseller()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 1100;

export default {
  props: {
    resellerInfo: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      updateInfo: null,
      updateImage: null,
      previewImage: null,
      alarmCenters: [],
      resellerTypes: [{ id: "alarm_center", name: "Larmcentraler" }],
      pricelist: [
        {
          id: "cash",
          active: false,
          prices: [
            { id: "SRT306", name: "SRT306", price: "3450", price_type: "total" },
            { id: "SRT306i", name: "SRT306i", price: "4625", price_type: "total" },
            { id: "SRT306-G2", name: "SRT306 G2", price: "3850", price_type: "total" },
            { id: "SRT326", name: "SRT326", price: "2780", price_type: "total" },
            { id: "SRT330", name: "SRT330", price: "5900", price_type: "total" },
            { id: "SRT334", name: "SRT334", price: "1670", price_type: "total" },
            { id: "SRT405", name: "SRT405", price: "4373", price_type: "total" },
            { id: "SRT405i", name: "SRT405i", price: "0", price_type: "total" },
            { id: "SRT406", name: "SRT406", price: "4601", price_type: "total" },
            { id: "SRT406i", name: "SRT406i", price: "5191", price_type: "total" },
            { id: "SRT430", name: "SRT430", price: "0", price_type: "total" },
            { id: "SRT278", name: "SRT278", price: "0", price_type: "total" },
            { id: "SRT341", name: "SRT341", price: "0", price_type: "total" },
            { id: "TWIG-Neo", name: "TWIG Neo", price: "2500", price_type: "total" },
            { id: "TWIG-IPS", name: "TWIG Neo SRD", price: "4625", price_type: "total" },
            { id: "TWIG-Neo-Mandown", name: "TWIG Neo MD", price: "3175", price_type: "total" },
            { id: "TWIG-Neo-SRD-MD", name: "TWIG Neo SRD MD", price: "0", price_type: "total" },
            { id: "TWIG-One-GPS", name: "TWIG One", price: "4625", price_type: "total" },
            { id: "TWIG-One-SRD", name: "TWIG One SRD", price: "4625", price_type: "total" },
            { id: "TWIG-One-EX", name: "TWIG One EX", price: "4625", price_type: "total" },
            { id: "TWIG-Beacon", name: "TWIG Beacon", price: "1670", price_type: "total" },
            { id: "TWIG-Beacon-EX", name: "TWIG Beacon EX", price: "0", price_type: "total" },
            { id: "EMERIT-WG100", name: "EMERIT WG100", price: "0", price_type: "total" },
            { id: "TELTONIKA-FMC003", name: "TELTONIKA FMC003", price: "0", price_type: "total" },
            { id: "SecurBas", name: "SecurBas", price: "20", price_type: "month" },
            { id: "SecurFull", name: "SecurFull", price: "109", price_type: "month" },
            { id: "SecurdiaCare", name: "SecurdiaCare", price: "19", price_type: "month" },
            { id: "Simkort", name: "Simkort", price: "20", price_type: "month" },
            { id: "Larmcentral", name: "Larmcentral", price: "69", price_type: "month" },
          ],
        },
        {
          id: "rental",
          active: false,
          prices: [
            { id: "SRT306", name: "SRT306", price: "278", price_type: "month" },
            { id: "SRT306i", name: "SRT306i", price: "278", price_type: "month" },
            { id: "SRT306-G2", name: "SRT306 G2", price: "309", price_type: "month" },
            { id: "SRT326", name: "SRT326", price: "268", price_type: "month" },
            { id: "SRT330", name: "SRT330", price: "379", price_type: "month" },
            { id: "SRT334", name: "SRT334", price: "69", price_type: "month" },
            { id: "SRT405", name: "SRT405", price: "309", price_type: "month" },
            { id: "SRT405i", name: "SRT405i", price: "0", price_type: "month" },
            { id: "SRT406", name: "SRT406", price: "309", price_type: "month" },
            { id: "SRT406i", name: "SRT406i", price: "309", price_type: "month" },
            { id: "SRT430", name: "SRT430", price: "0", price_type: "month" },
            { id: "SRT278", name: "SRT278", price: "0", price_type: "month" },
            { id: "SRT341", name: "SRT341", price: "0", price_type: "month" },
            { id: "TWIG-Neo", name: "TWIG Neo", price: "219", price_type: "month" },
            { id: "TWIG-IPS", name: "TWIG Neo SRD", price: "278", price_type: "month" },
            { id: "TWIG-Neo-Mandown", name: "TWIG Neo MD", price: "219", price_type: "month" },
            { id: "TWIG-Neo-SRD-MD", name: "TWIG Neo SRD MD", price: "0", price_type: "month" },
            { id: "TWIG-One-GPS", name: "TWIG One", price: "278", price_type: "month" },
            { id: "TWIG-One-SRD", name: "TWIG One SRD", price: "278", price_type: "month" },
            { id: "TWIG-One-EX", name: "TWIG One EX", price: "278", price_type: "month" },
            { id: "TWIG-Beacon", name: "TWIG Beacon", price: "69", price_type: "month" },
            { id: "TWIG-Beacon-EX", name: "TWIG Beacon EX", price: "0", price_type: "month" },
            { id: "EMERIT-WG100", name: "EMERIT WG100", price: "0", price_type: "month" },
            { id: "TELTONIKA-FMC003", name: "TELTONIKA FMC003", price: "0", price_type: "month" },
            { id: "SecurBas", name: "SecurBas", price: "20", price_type: "month" },
            { id: "SecurFull", name: "SecurFull", price: "89", price_type: "month" },
            { id: "SecurdiaCare", name: "SecurdiaCare", price: "19", price_type: "month" },
            { id: "Simkort", name: "Simkort", price: "20", price_type: "month" },
            { id: "Larmcentral", name: "Larmcentral", price: "69", price_type: "month" },
          ],
        },
        {
          id: "kickback",
          active: false,
          prices: [
            { id: "60-month", name: "60 månader", price: "5000", price_type: "total" },
            { id: "36-month", name: "36 månader", price: "3000", price_type: "total" },
          ],
        },
      ],
    };
  },

  watch: {
    resellerInfo(newVal) {
      // eslint-disable-line
      this.updateInfo = _.cloneDeep(newVal);
      this.previewImage = this.updateInfo.logo;
      // check for missing price scheme
      if (this.updateInfo.pricelist.length < this.pricelist.length) {
        // check & sort in predefined order
        let cash = _.find(this.updateInfo.pricelist, { id: "cash" });
        let rental = _.find(this.updateInfo.pricelist, { id: "rental" });
        let kickback = _.find(this.updateInfo.pricelist, { id: "kickback" });

        if (!cash) {
          let c = _.find(this.pricelist, { id: "cash" });
          this.updateInfo.pricelist.push(_.cloneDeep(c));
        }
        if (!rental) {
          let r = _.find(this.pricelist, { id: "rental" });
          this.updateInfo.pricelist.push(_.cloneDeep(r));
        }
        if (!kickback) {
          let k = _.find(this.pricelist, { id: "kickback" });
          this.updateInfo.pricelist.push(_.cloneDeep(k));
        }

        this.updateInfo.pricelist.sort((a, b) => (a.id.length > b.id.length ? 1 : -1));
      }
    },
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    specialAccess() {
      return [
        { id: "order_sc_qty_default", name: this.$t("reseller_edit_modal.quality_enabled"), active: false },
        { id: "config", name: this.$t("reseller_edit_modal.configure_alarms"), active: false },
        { id: "alarm_report", name: this.$t("reseller_edit_modal.alarm_report"), active: false },
        { id: "create_customer", name: this.$t("reseller_edit_modal.create_customer"), active: false },
        { id: "users", name: this.$t("reseller_edit_modal.user"), active: false },
        { id: "sc_device_setting", name: this.$t("reseller_edit_modal.additional_services"), active: false },
        { id: "securtrack", name: this.$t("reseller_edit_modal.secur_track"), active: false },
        { id: "securtool", name: this.$t("reseller_edit_modal.secur_tool"), active: false },
        { id: "test_reminder", name: this.$t("reseller_edit_modal.alarm_reminder"), active: false },
        { id: "ac_securtrack", name: this.$t("reseller_edit_modal.alarm_centers_securTrack"), active: false },
        { id: "ac_securtool", name: this.$t("reseller_edit_modal.alarm_centers_securTool"), active: false },
      ];
    },
  },

  methods: {
    getAlarmCenters() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`)
        .then((response) => {
          this.alarmCenters = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    updateReseller() {
      this.$refs.formUpdateReseller.validate().then((success) => {
        if (!success) {
          return;
        }

        let pricelist = [];

        for (let i = 0; i < this.updateInfo.pricelist.length; i++) {
          let o = this.updateInfo.pricelist[i];

          if (o.active) pricelist.push(o);
        }

        if (pricelist.length == 0) {
          this.handleError(null, "Vänligen välj minst ett prisupplägg");
          return;
        }

        let headers = {
          "Content-Type": "multipart/form-data",
          "X-Access-Token": this.$store.state.token,
        };

        var formData = new FormData();

        let alarm_center = this.updateInfo.alarm_center;
        let special_access = this.updateInfo.special_access;

        if (this.updateInfo.reseller_type != "alarm_center") {
          alarm_center = "";

          if (special_access.indexOf("ac_securtrack") > -1) special_access.splice(special_access.indexOf("ac_securtrack"), 1);
          if (special_access.indexOf("ac_securtool") > -1) special_access.splice(special_access.indexOf("ac_securtool"), 1);
        }

        formData.append("name", this.updateInfo.name);
        formData.append("email", this.updateInfo.email);
        formData.append("phone", this.updateInfo.phone);
        formData.append("description", this.updateInfo.description);
        formData.append("company_number", this.updateInfo.company_number);
        formData.append("address", this.updateInfo.address);
        formData.append("zip_code", this.updateInfo.zip_code);
        formData.append("city", this.updateInfo.city);
        formData.append("reference", this.updateInfo.reference);
        formData.append("invoice", this.updateInfo.invoice);
        formData.append("contact_person", this.updateInfo.contact_person);
        formData.append("contact_phone", this.updateInfo.contact_phone);
        formData.append("contact_email", this.updateInfo.contact_email);
        formData.append("type", this.updateInfo.type);
        formData.append("reseller_type", this.updateInfo.reseller_type);
        formData.append("alarm_center", alarm_center);
        formData.append("pricelist", JSON.stringify(pricelist));
        formData.append("special_access", JSON.stringify(special_access));

        if (this.updateImage) formData.append("image", this.updateImage);

        this.axios
          .put(`${process.env.VUE_APP_SERVER_URL}/reseller/${this.updateInfo._id}`, formData, headers)
          .then(() => {
            // eslint-disable-line
            this.$emit("updateReseller");
            this.$modal.hide("update-reseller");
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    fileChangeUpdate() {
      var vm = this;

      if (this.$refs.imageUpdate.files.length > 0) {
        this.updateImage = this.$refs.imageUpdate.files[0];

        let fileReader = new FileReader();
        fileReader.onload = function(e) {
          vm.previewImage = e.target.result;
        };
        fileReader.readAsDataURL(this.updateImage);
      } else {
        this.updateImage = null;
        this.previewImage = this.updateInfo.logo;
      }
    },

    setPriceZero(typeId, priceId) {
      let o = _.find(this.updateInfo.pricelist, { id: typeId });
      if (o) {
        let p = _.find(o.prices, { id: priceId });
        if (p) p.price = "0";
      }
    },

    resetSchemePrices(schemeId, priceId) {
      let originalScheme = _.find(this.pricelist, { id: schemeId });
      let updateScheme = _.find(this.updateInfo.pricelist, { id: schemeId });
      if (originalScheme && updateScheme) {
        let originalPrice = _.find(originalScheme.prices, { id: priceId });
        let updatePrice = _.find(updateScheme.prices, { id: priceId });
        if (originalPrice && updatePrice) updatePrice.price = originalPrice.price;
      }
    },

    hide(id) {
      this.$refs.formUpdateReseller.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;

    if (this.user.role == "admin") this.getAlarmCenters();
  },
};
</script>
